import React from 'react';


const MaintenancePage = () => {
    const styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(51, 51, 51, 0.9)', // Dark background with transparency
            color: 'white',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            zIndex: 1000, // Ensure it is above other content
            padding: '20px',
            boxSizing: 'border-box',
        },
        coming: {
            marginBottom: '20px',
            marginTop: '50px'
        },
        heading: {
            color: 'rgb(189, 113, 0)',
        },
        icon: {
            color: 'white',
            margin: '0 10px',
            fontSize: '24px',
            transition: 'color 0.3s',
        },
        iconHover: {
            color: '#ddd',
        },
        mediaQuery: {
            padding: '10px',
            fontSize24: {
                fontSize: '24px',
            },
            fontSize16: {
                fontSize: '16px',
            },
            iconSize20: {
                fontSize: '20px',
            },
        },
    };

    return (
        <div style={styles.overlay}>
            <div style={styles.coming}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 style={styles.heading}>Página en mantenimiento</h2>
                            <p>Estará disponible pronto...</p>
                        </div>
                    </div>
                </div>
            </div>
            <div style={styles.coming}>
                <h2 style={styles.heading}>Atentamente el equipo de <a
              href="https://tecnologiasadministrativas.com/"
              rel="nofollow"
              target="_blank"
              style={{color: "orange"}}
            >
              {" "}
              TAE
            </a></h2>
            </div>
            <div className="social-icon">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            {/* <div className="icon">
                                <a href="#" style={styles.icon}><i className="fa fa-facebook"></i></a>
                                <a href="#" style={styles.icon}><i className="fa fa-twitter"></i></a>
                                <a href="#" style={styles.icon}><i className="fa fa-linkedin"></i></a>
                                <a href="#" style={styles.icon}><i className="fa fa-google-plus"></i></a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MaintenancePage;
